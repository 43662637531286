import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import hotelcon from "../../../assets/images/hotelcon.svg";
import star from "../../../assets/images/star.svg";
import client1 from "../../../assets/images/client1.png";
import top_layer from "../../../assets/images/angle_img_top.png";
import bottom_layer from "../../../assets/images/angle_img_bottom.png";

import "./ourCustomers.css";
const OurCustomersCard = () => {
  return (
    <>
      <div className="card clients_card mx-4">
        <img className="top_layer" src={top_layer} />
        <img className="bottom_layer" src={bottom_layer} />
        <div className="boxs">
          <div className="client_img">
            <img className="" src={client1} alt="Card image cap" />
          </div>
          <div className="card-body">
            <h6>Savan Nadiyadara</h6>
            <p>
              “Great place and fantastic option for living and meeting people
              from various countries. Highly recommend it!”
            </p>
            <p className="icon_content mt-3">
              <img src={hotelcon} /> Moon Co-living
            </p>
            <div className="ratting d-flex">
              <img src={star} /> <h4>4.9</h4>
            </div>
            <div className="linkBtn">
              <a href="#" class="link">
                View More{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                >
                  <path
                    d="M8.49935 15.5846C12.4114 15.5846 15.5827 12.4133 15.5827 8.5013C15.5827 4.58928 12.4114 1.41797 8.49935 1.41797C4.58733 1.41797 1.41602 4.58928 1.41602 8.5013C1.41602 12.4133 4.58733 15.5846 8.49935 15.5846Z"
                    stroke="#ED257D"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M7.60742 11.0008L10.1008 8.50042L7.60742 6"
                    stroke="#ED257D"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const OurCustomers = () => {
  return (
    <>
      <Container className="py-5 my-5">
        <Row>
          <Col md="4">
            <OurCustomersCard />
          </Col>
          <Col md="4">
            <OurCustomersCard />
          </Col>
          <Col md="4">
            <OurCustomersCard />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OurCustomers;
