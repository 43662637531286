import React from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import hourly_bookings from "../../../assets/images/hourly_bookings.png";
import copal_friendly from "../../../assets/images/copal_friendly.png";
import OffersCard from "../offersCard/OffersCard";

const BenefitsFlaminngo = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: 2,
  };
  const benefitData = [
    {
      img: hourly_bookings,
      title: `Hourly Bookings`,
      content: `The Flaminngo is the key to more than 4,000 hotels booking for 3, 6 or 24 hours.`,

      type: "Hostel",
    },
    {
      img: copal_friendly,
      title: `Copal Friendly`,
      content: `Copal incense benefits include removing energy blocks, calming the nervous system, strengthening the mind, promoting intuition, and creating balance.`,

      type: "Hostel",
    },
    {
      img: hourly_bookings,
      title: `Hourly Bookings`,
      content: `The Flaminngo is the key to more than 4,000 hotels booking for 3, 6 or 24 hours.`,

      type: "Hostel",
    },
  ];
  return (
    <>
      <Container>
        <h4 className="heading">Benefits of Flaminngo</h4>
        <Slider {...settings}>
          {benefitData.map((items) => {
            return (
              <>
                <OffersCard data={items} />
              </>
            );
          })}
        </Slider>
      </Container>
    </>
  );
};

export default BenefitsFlaminngo;
