import React, { useState } from "react";
import OffersCard from "../offersCard/OffersCard";
import hostel_pg_offer from "../../../assets/images/hostel_pg_offer.png";
import Coliving_offer from "../../../assets/images/Coliving_offer.png";

import calander_svg from "../../../assets/images/calander_svg.svg";
import { Col, Row, Container } from "react-bootstrap";
import Slider from "react-slick";
import "./offersForYou.css";

const offerData = [
  {
    img: hostel_pg_offer,
    title: "Hostel/PG",
    content: `Get uo to 20% off on Hostels Home stays Hourly stays!`,
    date: `Valid till 1st jan-2023`,
    type: "Hostel",
  },
  {
    img: Coliving_offer,
    title: "Co-living",
    content: `Get uo to 50% off on Co-living Home stays Hourly stays!`,
    date: `Valid till 1st jan-2023`,
    type: "Hostel",
  },
  {
    img: hostel_pg_offer,
    title: "Hostel/PG",
    content: `Get uo to 50% off on Co-living Home stays Hourly stays!`,
    date: `Valid till 1st jan-2023`,
    type: "Hostel",
  },
  {
    img: hostel_pg_offer,
    title: "Hostel/PG",
    content: `Get uo to 50% off on Co-living Home stays Hourly stays!`,
    date: `Valid till 1st jan-2023`,
    type: "Co-living",
  },
  {
    img: hostel_pg_offer,
    title: "Hostel/PG",
    content: `Get uo to 50% off on Co-living Home stays Hourly stays!`,
    date: `Valid till 1st jan-2023`,
    type: "Co-living",
  },
  {
    img: hostel_pg_offer,
    title: "Hostel/PG",
    content: `Get uo to 50% off on Co-living Home stays Hourly stays!`,
    date: `Valid till 1st jan-2023`,
    type: "Resorts",
  },
  {
    img: hostel_pg_offer,
    title: "Hostel/PG",
    content: `Get uo to 50% off on Co-living Home stays Hourly stays!`,
    date: `Valid till 1st jan-2023`,
    type: "Hotels",
  },
];

const OffersForYou = () => {
  const [offersData, setOffersData] = useState(offerData);
  const [activeIndex, setActiveIndex] = useState("all");

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: 2,
  };
  const typeCheck = (type) => {
    const inputValue = type;

    const filteredResults = offerData.filter(
      (item) =>
        item.type && item.type.toLowerCase().includes(inputValue?.toLowerCase())
    );
    setOffersData(filteredResults);
    console.log(filteredResults, "filteredResults");
    setActiveIndex(inputValue);
  };

  const typeCheckAll = (type) => {
    setOffersData(offerData);
    setActiveIndex(type);
  };
  return (
    <>
      <Container className="py-5 offer_section">
        <h4 className="heading">Offers For You</h4>
        <div>
          <ul className="btn_sec d-flex">
            <li
              onClick={() => typeCheckAll("all")}
              className={"all" === activeIndex ? "active" : ""}
            >
              All
            </li>
            <li
              onClick={() => typeCheck("hostel")}
              className={"hostel" === activeIndex ? "active" : ""}
            >
              Hostel/PG
            </li>
            <li
              onClick={() => typeCheck("Co-living")}
              className={"Co-living" === activeIndex ? "active" : ""}
            >
              Co-living
            </li>
            <li
              onClick={() => typeCheck("Hotels")}
              className={"Hotels" === activeIndex ? "active" : ""}
            >
              Hotels
            </li>
            <li
              onClick={() => typeCheck("Resorts")}
              className={"Resorts" === activeIndex ? "active" : ""}
            >
              Resorts
            </li>
          </ul>

          {/* <ul className="btn_sec d-flex">
            <li onClick={() => typeCheckAll()}>All</li>
          </ul>
          <ul>
            {items.map((item, index) => (
              <li
                key={index}
                className={index === activeIndex ? "active" : ""}
                onClick={() => typeCheck(item.name, index)}
              >
                {item.name}
              </li>
            ))}
          </ul> */}

          <Slider {...settings}>
            {offersData.map((items) => {
              return (
                <>
                  <OffersCard data={items} />
                </>
              );
            })}
          </Slider>
        </div>
      </Container>
    </>
  );
};

export default OffersForYou;
